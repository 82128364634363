// add the beginning of your app entry
import 'vite/modulepreload-polyfill'


import { addInitialPluginsAndHydrate } from './utils/conditioner'
// @ts-ignore
import AOS from 'aos';

import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
document.addEventListener('DOMContentLoaded', () => {
	AOS.init();
  });
///////////////////////////////
// Dynamic Lazy Load Modules //
///////////////////////////////

addInitialPluginsAndHydrate(document.documentElement)
console.log('🚀 main.ts: test changes')

